// ERROR PAGES ********************************************

.error-page {
  z-index: -1;
  margin-top: -50px;
  height: 50vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    width: 100%;
    margin: auto;

  }


  &__title,
  &__subtitle {
    text-shadow: #000000 0px 0px 100px;
    position: relative;
    text-align: center;
    margin: 0 auto;
    margin-bottom: -15px;
  }

  &__title {
    font-size: 9rem;
    color: white;
    font-weight: 900;
  }

  &__subtitle {
    font-size: 2.5rem;
    color: white;
    font-weight: normal;
  }

  &__img {
    width: 100%;
    height: 100%;
  }

}
