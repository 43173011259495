.alert-fecepgfjzdnh {
  background-color: @orange;
  padding: 2rem 5rem;
  margin-bottom: 1rem;
  border-radius: 5px;

  p {
    margin-bottom: 0;
    color: white;

    .fa {
      margin-right: 1rem;
      color: white;
    }
  }
}

.wrapper-fecepgfjzdnh {
  background-color: @orange;
  padding: 1rem 0;
  text-align: center;

  p {
    margin-bottom: 0;
    color: white;

    .fa {
      margin-right: 1rem;
      color: white;
    }
  }
}
