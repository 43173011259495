@font-face {
    font-family: 'Dosis';
    src: url('Dosis/Dosis-Bold.woff2') format('woff2'),
        url('Dosis/Dosis-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dosis';
    src: url('Dosis/Dosis-Regular.woff2') format('woff2'),
        url('Dosis/Dosis-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dosis';
    src: url('Dosis/Dosis-Light.woff2') format('woff2'),
        url('Dosis/Dosis-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Dosis';
    src: url('Dosis/Dosis-SemiBold.woff2') format('woff2'),
        url('Dosis/Dosis-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

