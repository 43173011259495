.video-item {
  position: relative;
  display: flex;
  gap: 1rem;
  flex-direction: column;
  height: 100%;
  padding-bottom: 2rem;

  &--see-more {
    .video-item {
      &__thumb {
        border: 1px solid #727272;

        &__img {
          filter: blur(10px);
          opacity: 0.4;
          cursor: auto;
        }
      }
    }
  }

  &__thumb {
    display: block;
    width: 100%;
    padding-bottom: calc(9 / 16 * 100%);
    overflow: hidden;
    height: 0;
    position: relative;

    &__img {
      .transition(all ease 500ms);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;

      &:hover {
        .scale(1.05);
      }
    }

    &__cta {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      z-index: 1;
      .translateY(-50%);
      color: white;
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 700;

      img {
        width: 75%;
      }
    }

    &--cover {
      padding-bottom: calc(4 / 3 * 100%);
    }
  }

  &__timer {
    background: rgba(255, 255, 255, 0.2);
    color: white;
    display: inline-block;
    align-items: flex-end;
    justify-content: flex-end;
    padding: .25rem .5rem;
    border-radius: .5rem;
    font-size: 1rem;
    line-height: 1;
  }

  &__content {
    display: grid;
    grid-gap: .5rem;
  }

  &__title {
    font-size: 1.4rem;
    color: white;
    text-decoration: none;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    max-height: 32px;
    padding-right: 3rem;
  }

  &__subtitle {
    font-size: 1.2rem;
    color: var(--text-color);
  }

  &__infos {
    display: flex;
    gap: 0.5rem;
  }

  &__views {
    font-size: 1.2rem;
    color: var(--text-color);
    margin: 0;
    opacity: .6;
  }

  iframe {
    width: 100% !important;
    height: 100%;
  }
}
