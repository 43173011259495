.video-detail {
  display: grid;
  grid-gap: 2rem;

  &__header {
    display: grid;
    grid-gap: .5rem;
  }

  &__tag {
    display: flex;
    align-items: center;
    color: black;
    background: white;
    padding: .5rem .75rem;
    border-radius: .5rem;
    line-height: 1;
    font-size: 1.3rem;

    &:hover {
      background: rgba(255, 255, 255, 0.6);
    }

    &--black {
      color: white;
      background: black;
    }

    &--green {
      background: @green;
      color: white;

      &:hover {
        background: @dark-green;
      }
    }

    &--transparent {
      color: white;
      background: transparent;
    }

    &--big {
      padding: 1rem;
      font-size: 1.5rem;
    }
  }

  &__tags {
    display: flex;
    gap: 0.25rem;
    flex-wrap: wrap;

    > ul ,
    > ul > li {
      display: contents;
    }
  }

  &__no-video {
    background: url('../../css/img/cloudflare-background.jpg') no-repeat center;
    background-size: cover;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 8rem 2rem;

    &__title {
      color: white;
      font-size: 2rem;
      margin-bottom: 0;
    }

    &__subtitle {
      color: var(--main-color);
    }
  }

  &__player {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 1rem;
    align-items: start;

    &__content {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: center;
      height: 100%;
      flex-direction: column;
    }
  }

  &__promo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
  }

  &__title {
    color: var(--text-color);
    font-weight: bold;
    font-size: 2.5rem;
    margin: 0;
  }

  &__alt-title {
    color: #FFF;
    font-size: 15px;
    font-weight: bold;
  }

  &__subtitle {
    color: @lighter-gray;
    font-size: 1.5rem;
  }

  &__views {
    margin-left: 1rem;

    &:before {
      content: @fa-var-eye;
      font-family: "Font Awesome 5 Pro";
      margin-right: .5rem;
    }
  }

  &__player {
    position: relative;
  }

  &__cta {
    margin: 2rem !important;
  }

  &__infos {
    padding: 2rem;

    &--elite {
      display: flex;
    }
  }

  &__cover {
    flex: 0 0 23rem;
    padding-right: 2rem;

    img {
      display: block;
      width: 100%;
    }
  }

  &__description {
    color: @white;
    font-size: 1.3rem;
    border-bottom: 1px solid @gray;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
  }

  &__actions {
    &__row {
      display: flex;
      width: 100%;
      margin-bottom: .5rem;
    }
  }

  &__action {
    background: var(--dark-main-color);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: .5rem;
    color: #fff;
    border: none;
    outline: none;
    text-decoration: none;
    padding: 1.5rem 2rem;
    width: 100%;
    font-size: 1.5rem;
    cursor: pointer;
    position: relative;
    text-align: center;

    &:hover {
      background-color: var(--main-color);
    }

    &--green {
      background: @green;
      color: white;

      &:hover {
        background-color: @dark-green;
      }
    }

    &__icon {
      pointer-events: none;

      &--red {
        color: var(--light-main-color);
      }
    }

    &--split {
      display: flex;

      .video-detail__action__text,
      .video-detail__action__icon {
        width: 100%;
      }

      .video-detail__action__icon {
        color: var(--light-main-color);
        text-decoration: none;
        cursor: pointer;
        padding: 0;
        outline: none;
        border-right: 1px solid var(--light-main-color);
        margin-right: 1rem;

      }
    }

    &-item {
      position: relative;
      flex: 1;
      padding: 0 .2rem;
    }

    &__alert {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: @green;
      border-radius: 1rem;
      color: white;
      margin: 0;
      opacity: 0;
      visibility: hidden;
      transition: opacity ease 250ms;
    }

    &--invalid {
      .video-detail__action__alert {
        opacity: 1;
        visibility: visible;
      }
    }
  }


  .jm-url, .jm-phone {
    color: var(--main-color);
  }

  .margin-player {
    &.straceo {
      .cta--straceo {
        display: block !important;
      }

      .mediawrappers {
        filter: blur(10px);
      }
    }

    .jw-tab-focus:focus {
      outline: none !important;
    }

    p {
      color: var(--main-color);
      font-size: 1.8rem;
      text-indent: 1rem;
    }
  }

  .contact-link {
    color: @white;
    font-size: 1.3rem;
    border-bottom: 1px solid @gray;
    margin-bottom: 2rem;
    padding-bottom: 2rem;

    p {
      margin: 0;
    }

    a {
      color: @green;
    }
  }

  &__links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  &__tag-list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin-bottom: .25rem;

    &__title {
      font-size: 1.3rem;
      color: var(--main-color);
      font-weight: bold;
      margin-right: .5rem;
    }

    &__item {
      font-size: 1.3rem;
      color: var(--light-main-color);
      margin-bottom: .25rem;
      margin-right: .25rem;
    }

    &__link {
      font-size: 1.3rem;
      color: var(--light-main-color);
    }
  }

  &__ctas {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;

    & > * {
      width: 100%;
    }
  }
}

.video-player {
  position: relative;

  &__content {
    padding-top: calc(9 / 16 * 100%);
    width: 100%;
    height: 0;
    position: relative;
  }

  &__player {
    position: absolute !important;
    width: 100%;
    top: 0;
    left: 0;

    z-index: 1;
  }

  &__poster {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    z-index: 2;
  }

  &__play {
    cursor: pointer;
    z-index: 3;
    position: absolute;
    background: 0 0;
    left: 50%;
    top: 50%;
    border: 3px solid white;
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
    .translate(-50%, -50%);
    outline: none;
    background: rgba(0, 0, 0, 0.5);

    &:before {
      content: '\f04b';
      font-family: 'Font Awesome 5 Pro';
      color: #ffffff;
      font-size: 3rem;
      display: block;
      font-weight: bold;
    }
  }
}

.my18pass-blur {
  .video-player {
    &__content {
      &.nsfw {
        padding-top: 56.25%;
        overflow: hidden;
        filter: blur(15px);

        .video-player {
          &__player {
            display: none;
          }
        }
      }
    }
  }
}


.video-pub {
  margin-top: 20px;

  img {
    max-width: 100%;
  }
}


/* BANNERS */

.free-banner {
  border: 2px solid #4bd5ea;
  background: #4bd5ea57;
  text-align: center;
  padding: 3rem;
  color: #ffffff;
  margin-bottom: 2rem;
  font-weight: 700;

  &__label {
    color: black;
    background: #4ad4e9;
    text-transform: uppercase;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: .25rem 1rem;
    font-weight: bold;
    font-size: 1.3rem;
  }

  &__underline {
    text-decoration: underline;
  }

  a {
    color: #ffffff;
    text-decoration: underline;
  }
}

.confirmation-buy {
  margin-top: 2rem;
  border: 1px solid @green;
  text-align: center;
  color: @green;
  padding: 2rem;
  background-color: transparent;
  margin-bottom: 2rem;

  a {
    color: @green;
    text-decoration: underline;
    font-weight: bold;
  }

  .facebook {
    color: @lighter-gray;
    display: inline-block;
    margin: 0;
    margin-right: 2rem;
  }
}


@media screen and (max-width: 768px) {
  .video-detail {
    &__player {
      grid-template-columns: 1fr;
    }

    &__promo {
      display: none;
    }

    &__cta {
      margin: 1rem 0 !important;
    }

    &__cover {
      flex: 1;
      padding: 0;
      margin-bottom: 2rem;
    }

    &__infos {
      &--elite {
        flex-wrap: wrap;
      }
    }

    &__links {
      flex-wrap: wrap;
      align-items: center;
    }

    &__tags {
      padding: 0;
      justify-content: center;
    }
    &__actions {
      width: 100%;
      margin-top: 1rem;
    }

    &__action {
      text-align: center;
    }
  }
}
