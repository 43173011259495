.main-text {
  text-align: center;
  margin-top: 20px;
  font-size: 15px;
  font-weight: 600;
  font-style: italic;
  color: #6D6D6D;

  h1 {
    font-size: 15px;
    font-weight: 200;
    margin: 0;
    display: inline;
  }
}

.bottom-text {
  display: block;
  line-height: 1;

  * {
    margin: 0;
    padding: 0 !important;
    font-size: 1.2rem !important;
    display: inline;
    text-align: justify !important;
    line-height: 1 !important;
  }

  h2 {
    color: var(--main-color);
    font-weight: bold;
  }

  p {
    color: @lighter-gray;
    font-weight: 200;

    a, strong {
      color: @lighter-gray;
      font-weight: bold;
    }
  }
}

.footer-tags {
  background-color: @dark-gray;
  color: @black;
  padding: 2rem;
  margin-bottom: 2rem;

  a {
    color: var(--main-color);
  }
}

.footer-tags-for-list {
  margin-top: 2rem;
}


/* CATEGORY DESCRIPTION */

.tag-detail {
  display: flex;
  margin-bottom: 2rem;
  align-items: flex-start;

  &__img {
    padding: 1rem;
    width: 30%;

    img {
      width: 100%;
      border-radius: .5rem;
      display: block;
    }
  }

  &__text {
    color: @lighter-gray;
    font-size: 1.2rem;
    width: 70%;
    height: 100%;
    position: relative;
  }

  &__title {
    padding: 1rem;
    color: white;
    font-size: 1.5rem;
    margin-top: 0;
    font-weight: bold;

    &:before {
      content: @fa-var-fire;
      color: var(--main-color);
      font-family: "Font Awesome 5 Pro";
      margin-right: .5rem;
    }
  }

  &__description {
    position: relative;
    height: 100%;
    max-height: 25.5rem;
    overflow: auto;
    padding: 1rem;

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 5px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background-color: @gray;
    }

    h2, h3, h4 {
      color: var(--main-color);
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: 1.6rem;
      font-weight: bold;
    }

    a {
      font-weight: bold;
      color: var(--main-color);
      text-decoration: none;

      &:hover {
        color: var(--main-color);
      }
    }
  }
}


.tag-text-wrapper {


  .tag-text {

  }
}


@media screen and (max-width: 768px) {
  .bottom-text {
    column-count: 1;
    column-gap: 2rem;
    column-rule-style: solid;
    column-rule-width: 1px;
    margin-top: 2rem;
    padding: 1.5rem;
  }

  .tag-description-wrapper {

    .tag-img {
      display: none;
    }

    .tag-text-wrapper {
      width: 100%;

      .tag-text {
        max-height: 15rem;
      }
    }
  }
}

.rtalabel {
  padding: 2rem;

  h1 {
    font-size: 2rem;
    margin: 1rem 0;
    color: @white;
  }

  p {
    margin: 1rem 0;

    color: @white;

    a {
      color: var(--main-color);
    }
  }
}

.show-hide-text {
  display: block;
  flex-wrap: wrap;
  padding: 10px;
  position: relative;
  transition: opacity .1s ease, height .3s ease 0s;

  &--show {
    overflow: visible;
    max-height: 100%;
    transition: opacity .1s ease, height .3s ease 0s;
    margin-bottom: 1rem;

    &:after {
      height: auto !important;
      max-height: 100% !important;
    }

    .show-hide-text {
      &__description {
        max-height: inherit;


        &:after {
          height: 0;
          opacity: 0;
        }
      }
    }
  }

  &__description {
    color: #fff;
    order: 2;
    margin: 0 auto;
    position: relative;
    max-height: 100px;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 70%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #000000 90%);
      opacity: 1;
      transition: opacity .1s ease, height .3s ease 0s;
    }

    a:hover,
    a {
      color: var(--main-color);
    }
  }

  &__button {
    background: 0 0;
    border: none;
    color: var(--main-color);
    text-align: right;
    margin: 0 5px auto;
    width: 100%;
    z-index: 2;
    outline: none;
    font-size: 1.3rem;
  }
}
