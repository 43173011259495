.partners {
    margin-bottom: 5rem;
    padding: 0;
    max-width: 1500px;
    display: flex;
    flex-direction: column;
    justify-self: center;

    &__header {
        display: flex;
        justify-content: center;

        h1 {
            text-align: center;
            padding: 3rem;
        }
    }

    &__list {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(3, 1fr);

        @media screen and (max-width: 1280px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media screen and (max-width: 920px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wra;
        width: 100%;
        height: auto;
        background-color: black;
        padding: 2rem;
        gap: 10px;
        border-radius: 20px;

        &__image {
            width: 100%;
            max-height: 150px;
            height: auto;
            border-radius: 10px;
            object-fit: cover;
        }

        &__title {
            font-size: 2.5rem;
            font-weight: 400;
            padding: 0;
            margin: 0;
            flex-grow: 1;
            max-width: 300px;
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            @media screen and (max-width: 920px) {
                max-width: 70vw;
            }
        }

        &__description {
            font-size: 16px;
            line-height: 1.4em;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            overflow-y: scroll;
            font-weight: 300;
            opacity: 0.5;

            p {
                padding: 0;
                font-weight: 300;
                margin: 0;
            }
        }

        &__button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 100%;
            padding: 1.5rem 0;
            text-align: center;
            text-transform: capitalize;
            height: fit-content;
            color: white;
            font-weight: bo;
            background-color: var(--main-color);
            transition: background-color 0.3s ease;
            border-radius: 10px;

            &__site-name {
                display: inline;
                max-width: 300px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                @media screen and (max-width: 920px) {
                    max-width: 70vw;
                }
            }

            &:hover {
                background-color: var(--light-main-color);
            }
        }
    }
}
