.footer {
  margin: 0 auto;
  width: 100%;
  color: white;
  padding: 0 8rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);

  &__links {
    display: flex;
    flex-wrap: wrap;
    row-gap: 1rem;
    column-gap: 1rem;
    padding: 2rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__link {
    font-size: 1.2rem !important;
    display: flex;
    text-decoration: underline;
    border-radius: 1rem;
    color: var(--text-color);
    margin: 0;
    padding: 0;
    align-items: center;
  }

  &__text {
    font-size: 1.2rem !important;
    color: white;
    width: 100%;
    font-weight: bold;

    a {
      color: white;
    }
  }
}

.languageDropdown {
  &__content {
    background: @dark-gray;
    padding: 0;
    border-radius: .5rem;
    width: 100%;
    border: none;
  }

  &__icon {
    position: relative;

    i {
      pointer-events: none;
      font-size: 1.5rem;
    }

    img {
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 0;
      height: 1rem;
      .translate(50%, -50%);
    }
  }

  &__item {
    padding: 1rem 1.5rem;
    font-size: 1.4rem;
    display: flex;
    gap: 1rem;
    color: #ececec;
    text-decoration: none;
    align-items: center;

    &:active,
    &:focus,
    &:hover {
      background: rgba(158, 158, 158, 0.2);
    }
  }

  &__flag {
    border-radius: .25rem;
    overflow: hidden;
    display: block;
    height: 1.5rem;
    aspect-ratio: 4/3;
    object-fit: cover;
  }
}
