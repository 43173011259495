.tags {
  display: grid;
  grid-gap: 2rem;

  &__subtitle {
    color: var(--text-color);
    opacity: .6;
    font-size: 1.5rem;
    text-align: center;
    margin: 0;
    font-weight: normal;
  }

  &__title {
    color: var(--text-color);
    font-size: 2.4rem;
    font-weight: bold;
    text-align: center;
    margin: 0;
  }

  &__description {
    font-weight: normal;
    color: var(--text-color);
    opacity: .6;
    font-size: 1.5rem;
    text-align: center;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &__dropdown {
      margin: 0 .5rem;
      position: relative;

      @media screen and (max-width: 768px) {
        width: 100%;
        margin: .5rem;

        .dropdown__content {
          width: 100%;
          margin: 0;
        }
      }
    }

    &__toggle {
      border-radius: .5rem;
      background-color: transparent;
      border: 2px solid white;
      color: var(--text-color);
      padding: 1rem;
      width: 100%;
    }

    &__content {
      border-radius: .5rem;
      background-color: black;
      min-width: 100%;
      padding: 10px;
      border: solid 2px white;
      cursor: pointer;
      text-align: left;
    }

    &__item {
      width: 100%;
      background-color: transparent;
      color: var(--text-color);
      border: none;
      white-space: nowrap;
      padding: .5rem;
      text-align: left;

      i {
        margin-right: 1rem;
      }
    }

    &__input {
      margin: 0 .5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      border-radius: .5rem;
      border: 2px solid white;

      @media screen and (max-width: 768px) {
        width: 100%;
      }

      span {
        color:var(--text-color);
        margin: 0 1rem;
      }

      input {
        padding: 1rem;
        color: var(--text-color);
        background: none;
        border: none;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.31);
        }
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 1.5rem;
    padding: 0;
    list-style: none;

    &-item {
      color: var(--text-color);
      text-transform: capitalize;
      text-decoration: none;

      &__counter {
        opacity: .3;
        margin-left: .5rem;

        i {
          margin-right: .5rem;
        }
      }
    }

    @media screen and (max-width: 1280px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media screen and (max-width: 920px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}



//********************** EFFECTS AND ANIMATIONS

.grow {
  display: inline-block;
  vertical-align: middle;
  -webkit-transition-duration: 0.2s;
  transition-duration: 0.2s;
  -webkit-transition-property: transform;
  transition-property: transform;

  &:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05);
  }
}

/* CAROUSEL */


.tags-carousel {
  &__item {
    text-decoration: none;
    font-style: normal;
    display: block;
    position: relative;
    padding-top: calc(3 / 4 * 100%);
    width: 100%;
    height: 0;
    overflow: hidden;
    background: black;
  }

  &__img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }

  &__text {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 30%;
    background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgb(0, 0, 0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#000000', GradientType=0);
    color: #fff;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2rem;
  }

  &__title {
    font-size: 2.5rem;
    font-weight: bold;
    color: white;
    text-decoration: none;
    margin: 0 auto;
    position: relative;
  }

  &__subtitle {
    color: white;
    font-size: 1.5rem;
    font-weight: normal;
    margin: 0;
    position: relative;
  }
}
