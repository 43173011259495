.breadcrumb-wrapper {
  text-align: center;
  font-size: 1.5rem;
    color: @white-semi-gray;
  padding: 1rem;

  &--left {
    text-align: left;
  }

  ol {
    list-style: none;
    padding: 0;


    li {
      display: inline-block;
      margin: 0 2px;

      a {
        color: var(--main-color);

        &:hover {
          color: inherit;
        }
      }
    }
  }
}
