
@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}


@-webkit-keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(0%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(0%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes wobble {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  15% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -5deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -5deg);
  }

  30% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 3deg);
  }

  45% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -3deg);
  }

  60% {
    -webkit-transform: translate3d(0%, 0, 0) rotate3d(0, 0, 1, 2deg);
    transform: translate3d(0%, 0, 0) rotate3d(0, 0, 1, 2deg);
  }

  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg);
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes flash-animate {
  0% {
    left: -30%;
  }
  50% {
    left: 130%;
  }
  100% {
    left: 130%;
  }
}

@-ms-keyframes flash-animate {
  0% {
    left: -30%;
  }
  50% {
    left: 130%;
  }
  100% {
    left: 130%;
  }
}

@-webkit-keyframes flash-animate {
  0% {
    left: -30%;
  }
  50% {
    left: 130%;
  }
  100% {
    left: 130%;
  }
}

@-moz-keyframes flash-animate {
  0% {
    left: -30%;
  }
  50% {
    left: 130%;
  }
  100% {
    left: 130%;
  }
}

@-o-keyframes flash-animate {
  0% {
    left: -30%;
  }
  50% {
    left: 130%;
  }
  100% {
    left: 130%;
  }
}

@-webkit-keyframes flash-animate {
  0% {
    left: -30%;
  }
  50% {
    left: 130%;
  }
  100% {
    left: 130%;
  }
}
