.faq-section {
  color: white;
  margin: 0 auto;

  .choice-section {
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      z-index: -1;
      width: auto;
      position: absolute;
      background-image: url(../../assets/faq/waves.svg);
      -webkit-background-size: cover;
      background-size: contain;
      background-position: bottom;
      left: 0;
      height: 100%;
      background-repeat: no-repeat;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    }

    .head-title {
      margin-top: 3rem;
      margin-bottom: 3rem;

      h1 {
        font-size: 3rem;
        font-weight: bold;
        text-align: center;
        margin: 0;
        margin-bottom: 0.5rem;
      }

      span {
        text-align: center;
        display: block;
        position: relative;
      }
    }

    .menu-list-subjects {
      max-width: 970px;
      padding-right: 0px;
      padding-left: 0px;
      position: relative;

      &:before {
        content: '';
        z-index: -1;
        width: 100%;
        position: absolute;
        background-image: url(../../assets/faq/faq-questions.svg);
        -webkit-background-size: contain;
        background-size: contain;
        background-position: right;
        height: 100%;
        background-repeat: no-repeat;
        right: -225px;
      }


      ul {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        grid-template-areas: ". ." ". ." ". ." ". ." ". .";
        grid-gap: 15px;
        margin: 0;
        margin-block-start: 0em;
        margin-block-end: 0em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        padding-inline-start: 0px;
        margin-bottom: 15px;

        li {
          display: flex;
          flex-direction: row;
          padding: 1.5rem 3rem;
          width: 100%;
          list-style: none;
          border-radius: 20px;
          background: #1d1d1d;
          border: 5px solid #151515;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.48);
          font-weight: bold;
          align-items: center;
          -webkit-transition: cubic-bezier(.21, 0, 0, 1) 0.2s;
          -moz-transition: cubic-bezier(.21, 0, 0, 1) 0.2s;
          -ms-transition: cubic-bezier(.21, 0, 0, 1) 0.2s;
          -o-transition: cubic-bezier(.21, 0, 0, 1) 0.2s;
          transition: cubic-bezier(.21, 0, 0, 1) 0.2s;
          cursor: pointer;

          &:hover {
            -webkit-transform: scale(1.04);
            -moz-transform: scale(1.04);
            -ms-transform: scale(1.04);
            -o-transform: scale(1.04);
            transform: scale(1.04);
            background-color: @orange;
            border: @orange;
            opacity: 1;
          }

          i {
            margin-right: 8px;
          }
        }

        .active {
          background-color: @orange;
          border: none;
          opacity: 0.9;
        }
      }
    }
  }

  .faq-subjects {
    width: 100%;
    position: relative;

    &:before {
      content: '';
      z-index: -2;
      background: rgb(18, 18, 18);
      background: -moz-linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(0, 0, 0, 1) 100%);
      background: -webkit-linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(0, 0, 0, 1) 100%);
      background: linear-gradient(180deg, rgba(18, 18, 18, 1) 0%, rgba(0, 0, 0, 1) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#121212", endColorstr="#000000", GradientType=1);
      width: 100%;
      position: absolute;
      height: 100%;

    }

    .faq-illustration {
      max-width: 970px;
      position: relative;
      margin: 0 auto;

      &:before {
        content: '';
        z-index: -1;
        width: 100%;
        position: absolute;
        background-image: url(../../assets/faq/faq-choices.svg);
        -webkit-background-size: contain;
        background-size: contain;
        background-position: center;
        height: 30vw;
        background-repeat: no-repeat;
        top: 2rem;
        left: -38vw;
      }

      .faq-subject-element {
        // display: none;
        z-index: 2;
        overflow: hiden;
        margin: 0 auto;
        margin-bottom: 15px;
        padding: 0rem 5rem 5rem 5rem;
        border-radius: 20px;
        background: #1d1d1d;
        border: 4px solid @orange;
        word-break: break-word;

        h2 {
          z-index: 1;
          margin: 0;
          margin-bottom: 0;
          margin-top: 0;
          border-radius: 16px;
          text-align: center;
          font-weight: bold;
          font-size: 2.5rem;
          position: sticky;
          background-color: rgba(29, 29, 29, 0.80);
          backdrop-filter: blur(10px);
          top: -1px;
          padding: 3rem;
          margin-left: -5rem;
          margin-right: -5rem;
        }

        div[itemprop="mainEntity"] {
          h3 {
            position: relative;
            margin-top: 0;
            font-weight: 600;

            &:before {
              content: '';
              z-index: 0;
              width: 100%;
              position: absolute;
              background-image: url(../../assets/faq/trait-courbe.svg);
              -webkit-background-size: contain;
              background-size: unset;
              background-position: left;
              height: 100%;
              top: -8px;
              color: #f77714;
              background-repeat: no-repeat;
              left: -54px;
            }
          }

          div[itemprop="text"] {
            opacity: 0.6;
            margin-bottom: 1rem;
            -webkit-transition: cubic-bezier(.21, 0, 0, 1) 0.2s;
            -moz-transition: cubic-bezier(.21, 0, 0, 1) 0.2s;
            -ms-transition: cubic-bezier(.21, 0, 0, 1) 0.2s;
            -o-transition: cubic-bezier(.21, 0, 0, 1) 0.2s;
            transition: cubic-bezier(.21, 0, 0, 1) 0.2s;

            &:hover {
              opacity: 0.9;
            }

            a {
              color: @orange;
            }

            p {

            }

            ul {
              margin-top: 1rem;

              li {
                list-style: none;

                &:before {
                  content: "\f105";
                  font-family: "Font Awesome 5 Pro";
                  display: inline-block;
                  margin-left: -1em;
                  width: 1.1em;
                }
              }
            }
          }
        }
      }
    }
  }
}


@media screen and (max-width: 992px) {
  .faq-section {
    margin: 15px;

    .choice-section {


      &:before {
        display: none;
      }

      .head-title {

        h1 {
          font-size: 3rem;
        }

      }

      .menu-list-subjects {

        &:before {
          display: none;
        }

        ul {

          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          grid-template-areas: "." "." "." "." "." "." "." "." "." ".";
          grid-gap: 15px;
        }
      }
    }

    .faq-illustration {
      &:before {
        display: none;
      }
    }
  }
}
