@plq-red: hsla(346, 83%, 57%, 1);
@plq-green: hsla(101, 60%, 50%, 1);
@plq-blue: hsl(221, 100%, 61%, 1);
@plq-yellow: hsl(58, 100%, 53%, 1);
@plq-orange: hsl(32, 90%, 55%);
@plq-pink: hsl(293, 83%, 72%);

@plq-black: hsla(0, 0%, 4%, 1);
@plq-gray: hsla(0, 0%, 50%, 1);
@plq-mid-gray: hsla(0, 0%, 70%, 1);
@plq-light-gray: hsla(0, 0%, 90%, 1);
@plq-lighter-gray: hsla(0, 0%, 95%, 1);
@plq-white: hsla(0, 0%, 100%, 1);

body {
  margin: 0;
  font-size: 15px;
}

.plq {
  &__icon {
    width: 20px;
    height: 20px;
    color: white;
    margin-right: 10px;
    fill: white;
  }

  &--green {
    &::before {
      background: @plq-red;
    }

    a {
      color: @plq-white;
      background: @plq-green;
    }

    &:hover {
      a {
        background: darken(@plq-green, 10%) !important;
      }
    }
  }

  &--red {
    &::before {
      background: @plq-green;
    }

    a {
      color: @plq-white;
      background: @plq-red;
    }

    &:hover {
      a {
        background: darken(@plq-red, 10%) !important;
      }
    }
  }

  &--yellow {
    &::before {
      background: @plq-green;
    }

    .plq__icon {
      fill: black;
    }

    a {
      color: @plq-black;
      background: @plq-yellow;
    }

    &:hover {
      a {
        background: darken(@plq-yellow, 10%) !important;
      }
    }
  }

  &--orange {
    &::before {
      background: @plq-green;
    }

    a {
      color: @plq-white;
      background: @plq-orange;
    }

    &:hover {
      a {
        background: darken(@plq-orange, 10%) !important;
      }
    }
  }

  &--pink {
    &::before {
      background: @plq-green;
    }

    a {
      color: @plq-white;
      background: @plq-pink;
    }

    &:hover {
      a {
        background: darken(@plq-pink, 10%) !important;
      }
    }
  }

  &--blue {
    &::before {
      background: @plq-red;
    }

    a {
      color: @plq-white;
      background: @plq-blue;
    }

    &:hover {
      a {
        background: darken(@plq-blue, 10%) !important;
      }
    }
  }
}
