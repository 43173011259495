/* Fonts */
@import (less) "./fonts/font.less";
@import (less) "./fonts/icons.less";

@import (less) "mixins/grid-layout.less";
@import (less) "mixins/mixins.less";

/* Components */

@import "components/adblock.less";
@import "components/header.less";
@import "components/footer.less";

@import "components/dropdown.less";
@import "components/list-filter.less";
@import "components/breadcrumb.less";
@import "components/alert.less";
@import "components/search-filter.less";

@import "pages/charte.less";
@import "pages/error-pages.less";
@import "pages/video-detail.less";
@import "pages/settings.less";
@import "pages/faq.less";

@import "components/categories.less";
@import "components/cta.less";
@import "components/upsell-banner.less";
@import "components/daily-videos.less";
@import "components/disclaimer.less";
@import "components/log-form.less";
@import "components/modal-offer.less";
@import "components/pagination.less";
@import "components/plq.less";
@import "components/promo-widgets.less";
@import "components/pub.less";
@import "components/seo.less";
@import "components/userstate.less";
@import "components/video-item";
@import "components/video-list.less";
@import "components/_dalenys-modal";
@import "components/partners.less";

@import "empty-base.less";

*, ::after, ::before {
  box-sizing: border-box;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

button {
  font-family: inherit;
  border: inherit;
  background: inherit;
  cursor: pointer;
}

/* ---------------------------------------------------------------------------------- DEBUT STYLE COULEURS */
:root {
    --text-color: #fdf0d5;
    --main-color: #F2CC5B;
    --light-main-color: #f9db83;
    --dark-main-color: #2b2923;
    --extra-dark-color: #0d0d1a;
    --container-width: 1440px;
    --border-color: #000000;
}

html {
  font-family: Arial, sans-serif !important;
  font-weight: normal;
  font-style: normal;
  font-size: 62.5%;
  height: 100%;
  color: var(--text-color);
  letter-spacing: .25px;
}

body {
  font-size: 1.5rem; /* =15px */
  min-height: 100vh;
  margin: 0;
  background: #2B2923;

  iframe[width="0px"] {
    display: none;
  }


  &.has-cta-bottom {
    footer {
      padding-bottom: 7rem;
    }
  }

  iframe[width="0px"] {
    display: none;
  }
}

.main {
  flex: 1;
  width: 100%;
  position: relative;

  &__header {
    margin: 0 auto;
    border: none;
    position: sticky;
    top: 0;
    z-index: 1;
  }

  &__content {
    position: relative;
    z-index: 0;
    padding: 0 8rem;
    grid-gap: 1rem;
    display: grid;
    background: #151515;

    > * {
      padding: 1rem;
    }
  }

  @media screen and (max-width: 1280px ) {
    &__content {
      padding: 0 3rem;
    }
  }

  @media screen and (max-width: 920px) {
    &__content {
      padding: 0;
    }
  }

}

.force-blur {
  filter: blur(20px) !important;
}

.rd {
  direction: rtl;
  unicode-bidi: bidi-override;
}