.charte {
  padding: 5rem;

  &__title {
    font-size: 3rem;
    color: var(--main-color);
    margin-top: 0;
    text-align: center;

    .fas {
      font-size: 2rem;
    }
  }

  &__content {
    color: @lighter-gray;

    strong, a {
      color: var(--main-color);
    }

    .identity {
      margin: 2rem auto;
      text-align: justify;

      img {
        height: 1.6rem;
        vertical-align: text-bottom;
      }
    }

  }

  h2 {
    color: var(--main-color);
    font-size: 2rem;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 1rem;
  }
}
