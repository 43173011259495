.search-filter {
  flex: 0 0 25rem;
  border-right: 1px solid @gray;
  display: grid;
  grid-row-gap: 1rem;
  align-items: flex-start;
  position: sticky;
  top: 20rem;

  &__title {
    font-size: 1.5rem;
    color: white;
    margin: 0;
    line-height: 25px;
    font-weight: 700;

    i {
      color: var(--main-color);
      margin-right: .5rem;
    }
  }

  &__list {
    max-height: 55rem;
    overflow: auto;
    display: grid;
    grid-row-gap: .75rem;

    &::-webkit-scrollbar-track {
      background-color: transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar {
      width: 5px;
      background-color: rgba(255, 255, 255, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--main-color);
    }
  }

  &__link {
    font-size: 1.5rem;
    color: white;
    opacity: .4;
    width: 100%;
    .transition(color ease 250ms);
    position: relative;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;

    &--active {
      color: var(--main-color);
      opacity: 1;
    }

    &:hover {
      opacity: 1;
    }
  }

  &__item {
    white-space: nowrap;
    overflow: hidden;
    padding: 0 2rem;
    width: 100%;

    i {
      margin-right: 1rem;
    }
  }
}

.tag-list {
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;

  &__item {
    margin: 5px;
  }

  &__link {
    background-color: var(--main-color);
    color: #fff;
    display: block;
    padding: 10px;
        border-radius: 5px;
  }
}