.dropdown-user {
  display: flex;

  &__button {
    color: white;
    padding: 0 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    background: @dark-gray;
    border-radius: 1rem;
  }

  &__link {
    color: white;
    padding: .5rem 2rem;
    display: block;
    width: 100%;
    white-space: nowrap;
    text-decoration: none;

    &:hover, &:focus, &:active {
      background-color: @gray;
    }
  }

  &__item {
    display: flex;
    width: 100%;
  }

  &__icon {
    margin-right: 1rem;
    color: var(--main-color);
  }
}

.dropdown-list {
  .cta {
    width: 100%;
  }

  &__button {
    padding: .75rem 1.5rem;
    background-color: var(--main-color);
    border: none;
    color: @white;
    border-radius: .5rem;
  }

  &__content {
    background-color: var(--dark-main-color);
    border: none;
    border-bottom: 5px solid var(--main-color);
    padding-bottom: 0;
    border-radius: .5rem;
    padding-top: 0;
    max-height: 20rem;
    overflow: auto;
    width: 100%;
  }

  &__link {
    .transition(background ease 500ms);
    color: @white;
    padding: 1rem 2rem;
    display: block;
    text-decoration: none;
    white-space: nowrap;

    &--active,
    &:hover, &:focus, &:active {
      background-color: var(--main-color) !important;
      color: @white;
    }
  }

  &__icon {
    margin-right: 5px;
  }
}


.dropdown-tags {
  &__content {
    width: 100%;
    background: hsl(27 30% 15%);
    margin-top: 0;
    flex-direction: column;
    border-radius: 1rem;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  &__link {
    display: block;
    font-size: 1.4rem;
    color: #fff;
    text-transform: none;
    text-decoration: none;
    padding: .75rem 1rem;
    .transition(padding-left ease 250ms);

    &--bold {
      font-weight: bold;
    }

    &:hover {
      padding-left: 1.5rem;
    }
  }

  &__item {
    display: flex;
  }
}

@media screen and (max-width: 920px) {

  .dropdown-tags {
    flex-wrap: wrap;

    &__content {
      position: relative;
      top: 0;
      left: 0;
      background: hsl(0deg 0% 15%);
    }

    &__list {
      grid-template-columns: 1fr;
    }

    &--mobile {
      position: unset;

      .dropdown-tags {
        &__toggle {
          display: flex;
          align-items: center;
          height: 100%;
          padding: 0 1.5rem;
          font-size: 1.8rem;
          color: var(--main-color);
        }

        &__content {
          position: absolute;
          width: 100%;
          top: 100%;
          left: 0;
          margin: 0;
              background: hsl(27 30% 15%);
          padding: 0;
          z-index: 1;
        }

        &__form {
          margin-bottom: 0;
        }

        &__input {
          border-radius: 0;
        }
      }

    }
  }
}
