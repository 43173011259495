.dropdown {
  position: relative;

  &__toggle {
    cursor: pointer;

    &--carret {
      &:after {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: .5rem;
        vertical-align: middle;
        border-top: .5rem dashed;
        border-right: .5rem solid transparent;
        border-left: .5rem solid transparent;
      }
    }
  }

  &__content {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    min-width: 160px;
    padding: 5px 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    overflow: hidden;
    display: none;

    &--right {
      left: auto;
      right: 0;
    }

    @media screen and (max-width: 768px){
      &--mobile-left {
        left:0;
        right: 0;
      }
    }

    &--top {
      bottom: 100%;
      top: auto;
      right: 0;
      margin-bottom: .5rem;
      margin-top: 0;
    }
  }

  &--open {
    .dropdown {
      &__content {
        display: flex;
      }

      &__toggle {
        &--carret {
          &:after {
            border-top: 0;
            border-bottom: .5rem dashed;
            border-right: .5rem solid transparent;
            border-left: .5rem solid transparent;
          }
        }
      }
    }
  }
}
