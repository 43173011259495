.upsell-banner {
    --upsell-color: #19d95f;
    border-radius: 1rem;
    background: var(--upsell-color);
    color: white;
    padding: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;

    &__header {
        display: flex;

        &:before {
            content: @fa-var-badge-percent;
            font-family: "Font Awesome 5 Pro";
            color: white;
            opacity: .3;
            font-size: 3rem;
            font-weight: bold;
            padding-right: 2.5rem;
        }

        &__text {
            flex: 1;
            display: none;
        }

        &__title {
            font-weight: bold;
        }

        &__subtitle {
            text-decoration: underline;
        }
    }

    &__content {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__text {
        line-height: 1.2;
        padding-right: 1rem;
    }

    &__close {
        display: none;
    }

    &__cta {
        background: white;
        color: black;
        padding: 1rem 2rem;
        display: block;
        border-radius: 1rem;
        white-space: nowrap;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        padding: 1rem;

        &__header {
            width: 100%;

            &__text {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }
        }

        &__content {
            flex-direction: column;
            padding: 2rem;
            background: rgba(0, 0, 0, 0.3);
            border-radius: 1rem;
            margin-top: 1rem;
        }

        &__cta {
            width: 100%;
            text-align: center;
            margin-top: 1rem;
            padding: 1rem;
        }

        &__text {
            padding: 0;
            text-align: center;
        }

        &__title {
            font-weight: bold;
            line-height: 1;
        }

        &__subtitle {
            text-decoration: underline;
        }

        &__close {
            display: block;
            color: white;
            padding: .5rem;
        }
    }
}

@media screen and (max-width: 768px) {
    .upsell-banner {
        &__content {
            display: none;
        }
    }
    .upsell-banner_open {
        .upsell-banner__content {
            display: flex;
        }
    }
}
