@base-grid-col: 12;

.grid-layout {
  --container-width: 100%;

  &__container {
    width: var(--container-width);
    max-width: 100%;
    margin: 0 auto;
  }

  &__row {
    --gap-between-col: .5rem;

    display: flex;
    flex-wrap: wrap;
    width: 100%;

    .row-loop(@base-grid-col; ~"");

    @media screen and (max-width: 920px) {
      --gap-between-col: 1rem;
    }

    @media screen and (min-width: 920px) {
      .row-loop(@base-grid-col; -sm);
    }

    @media screen and (min-width: 1280px) {
      .row-loop(@base-grid-col; -md);
    }

    &--inline {
      flex-wrap: nowrap;
      align-items: flex-start;
    }
  }

  &__col {
    flex: 1 0 0%;
    padding-left: calc(var(--gap-between-col) * .5);
    padding-right: calc(var(--gap-between-col) * .5);

    .col-loop(@base-grid-col; ~'');

    @media screen and (min-width: 920px) {
      .col-loop(@base-grid-col; -sm);
    }

    @media screen and (min-width: 1280px) {
      .col-loop(@base-grid-col; -md);
    }
  }
}


@media screen and (min-width: 920px) {
  .visible-xs {
    display: none !important;
  }
}

@media screen and (max-width: 920px) {
  .hidden-xs {
    display: none !important;
  }
}


/* Set grid size from row */

.row-loop(@iteration; @col-type) when not(@iteration=0) {
  &--col@{col-type}-@{iteration} {
    .grid-layout__col {
      flex: 0 0 auto;
      width: calc(100% / @iteration);
    }
  }
  .row-loop((@iteration - 1); @col-type);
}

;

/* Set grid size from col */

.col-loop(@iteration; @col-type) when not(@iteration=0) {
  &@{col-type}-@{iteration} {
    width: calc((100% / @base-grid-col) * ~"@{iteration}");
    flex: 0 0 auto;
  }
  .col-loop((@iteration - 1); @col-type);
}

;
