.daily-videos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: .5rem;

  .video-item {
    padding-bottom: 1rem !important;
    height: 100%;

    &__thumb {
      padding-bottom: calc(3 / 4 * 100%);
    }
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
}


.daily-video {
  display: flex;
  grid-row: 1/3;
  grid-column: 2/5;

  @media screen and (max-width: 768px) {
    grid-row: 1/2;
    grid-column: 1/3;
  }

  &__label {
    position: absolute;
    background: #f1cc5a;
    color: #2b2923;
    box-shadow: 0 5px 15px rgba(0,0,0,0.15);
    border: 2px solid #2b2923;
    font-weight: bold;
    z-index: 1;
    padding: 1rem;
    top: 1rem;
    right: 1rem;
    border-radius: 1rem;
  }

  &__thumb {
    aspect-ratio: 16/9;
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

}


.daily-video__title {
  font-size: 1.8rem;
  color: #fffef3;
  font-weight: bold;
  text-shadow: 2px 2px #1e1e3d;
  background: #4d846a;
  padding: 1rem 2rem;
  border: 2px solid var(--border-color);
  border-radius: 1rem;
}

.daily-video__subtitle {
  font-size: 5rem;
  color: #fffef3;
  font-weight: bold;
  text-shadow: 3px 3px #1e1e3d;
  max-width: 100rem;
  text-align: center;
}

.daily-video__cta {
  padding: 1.5rem 2rem;
  background: #dd3d73;
  border: 2px solid var(--border-color);
  box-shadow: 3px 3px 0 var(--border-color);
  color: #fffef3;
  border-radius: 1rem;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
}