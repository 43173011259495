.navigation {
  position: relative;

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: var(--container-width);
    background-color: #F2CC5B;
    max-width: 100%;
    padding: 1rem 8rem;
    gap: 3rem;
  }

  &__logo {
    background-image: url('../img/madame-porno-logo.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 25rem;
    height: 6rem;
    padding: 0;
    cursor: pointer;
  }

  &__left {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 2rem;
    padding: 0;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  &__sub {
    padding: 1rem 8rem;
    background-color: black;
    position: relative;
    width: var(--container-width);
    max-width: 100%;
  }

  &__mobile {
    display: flex;
    padding: 0 1rem;
  }

  &__toggle {
    display: grid;
    grid-template-rows: repeat(3, 4px);
    grid-template-columns: 25px;
    grid-gap: 5px;
    padding: 1rem;
    align-items: center;
    place-content: center;
    border-radius: 1rem;

    &__bar {
      width: 100%;
      background: #2B2923;
      height: 100%;
      display: block;
      border-radius: 5px;

      &--short {
        width: 50%;
        justify-self: flex-end;
      }
    }
  }

  &__content {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    align-items: center;
    gap: 1rem;
    width: 100%;
  }

  &__item {
    display: flex;

    &--end {
      margin-left: auto;
    }

    &--plq {
      position: relative;

      &:before {
        content: attr(data-plans-q-notifications);
        position: absolute;
        z-index: 2;
        border-radius: 4rem;
        display: flex;
        right: -1rem;
        top: 2rem;
        align-items: center;
        justify-content: right;
        text-align: right;
        padding: 0 .70rem;
        color: white;
        .translateY(-50%);
        font-size: 1.2rem;
        font-weight: bold;
        line-height: 2rem;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.50);

        @media screen and (max-width: 768px) {
          right: 2rem;
        }
      }

      &:hover {
        background-color: inherit;
      }
    }
  }

  &__cta {
    border-radius: 1rem;
    border: none;
    align-items: center;
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    padding: 1rem 1.5rem;
    background: transparent;
    cursor: pointer;
    .transition(all ease 250ms);

    &--colored {
      color: #2B2923;
      background: white;
    }

    &--border {
      border: 2px solid #2B2923;
      color: #2B2923;
    }
  }

  &__link {
    display: flex;
    gap: 1rem;
    color: #FFFEF3;
    font-weight: bold;
    font-size: 1.3rem;
    align-items: center;
    transition: all ease 250ms;
    border-radius: 1rem;
    padding: 1rem;

    i {
      opacity: .5;
    }

    &--webcam {
      i {
        .animation(blink 1s ease-in-out infinite alternate);
        opacity: 1;
        color: red;
      }
    }

    &:hover {
      color: var(--main-color);
    }
  }
}

@media screen and (max-width: 1280px ) {
  .navigation {
    &__main,
    &__sub {
      padding: 1rem 3rem;
    }
  }
}

@media screen and (max-width: 920px) {
  .navigation {
    z-index: 1001;

    &__item {
      width: 100%;

      &--separator {
        height: 1px;
        background: rgba(255, 255, 255, 0.2);
      }
    }

    &__main,
    &__sub {
      padding: 0 1rem;
    }

    &__logo {
      height: 6rem;
      width: 12rem;
      margin: 1rem;
    }

    &__backdrop {
      opacity: 0;
      visibility: hidden;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: hsla(0, 0%, 4%, 0.8);
      backdrop-filter: blur(10px);
      z-index: 2;
      transition: all ease 250ms;
    }

    &__sub {
      border: none;
      padding: 0;
      opacity: 0;
      visibility: hidden;
      position: fixed;
      left: 0;
      top: 0;
      height: 100%;
      width: 35rem;
      max-width: 80%;
      z-index: 10;
      flex-direction: column;
      justify-content: flex-start;
      grid-row-gap: 1rem;

      background-color: #FFFEF3;
      background-image: url('../img/square-pattern.svg');
      background-size: 4rem;

      &--show {
        opacity: 1;
        visibility: visible;

        & + .navigation__backdrop {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    &__item {
      &--end {
        margin-left: initial;
      }
    }

    &__link {
      font-size: 1.5rem;
      width: 100%;
      flex: 1;
      color: #2B2923;
      border: 2px solid #2B2923;

      &--mobile-dropdown {
        font-size: 0;
        flex: 0;
        text-align: center;
        display: block;
        border-left: 1px solid @gray;

        &:after {
          display: none !important;
        }

        &:before {
          content: @fa-var-chevron-down;
          font-family: "Font Awesome 5 Pro";
          font-size: 1.5rem;
          font-weight: bold;
        }
      }
    }

    &__left {
      position: relative;
      justify-content: space-between;
      width: 100%;
    }

    &__content {
      overflow: auto;
      flex-direction: column;
      align-items: baseline;
      padding: 2rem;
      height: 100%;
    }
  }
}

/******************************* */

.plan-q {
  background: transparent !important;


  &[data-plans-q-notifications=""] {
    &:before {
      content: none;
    }

    a:after {
      content: "\f054";
      display: inline-block;
      font-family: "Font Awesome 5 Pro";
      font-weight: bold;
      color: white;
      margin-left: 1rem;
    }
  }

  &[data-plans-q-notifications] {
    &:before {
      right: 2rem;
    }
  }

  a {
    font-size: 2.3rem;
    padding: 1.5rem;
    margin: 1rem;
    text-align: center;
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;

    display: block;
    cursor: pointer;
    position: relative;
    border-radius: 1rem;

    i {
      display: inline-block;
      margin-right: 1rem;
    }
  }
}

.snapsex {
  position: relative;
  overflow: hidden;
  background: #fede06;
  min-height: 100px;

  a {
    text-align: center;
    color: black;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 1.4rem;
    display: block;
    cursor: pointer;
    position: relative;
    padding: 2rem;
    text-decoration: none;
    font-weight: bold;
  }
}

.searchForm {
  display: flex;
  border-radius: 1rem;
  overflow: hidden;
  background: transparent;
  width: 100%;

  &__submit {
    background: #2B2923;
    color: var(--text-color);
    padding: 1rem 2rem;
    border: none;
    display: flex;
    align-items: center;

    &:before {
      content: '\f002';
      font-family: 'Font Awesome 5 Pro';
      font-weight: bold;
    }
  }

  &__input {
    padding: 1rem 2rem;
    width: 100%;
    outline: none;
    font-size: 1.5rem;
    border: none;
    background: #FFFEF3;
    border-radius: 1rem 0 0 1rem;
  }

  &__sub {
    display: flex;
    gap: 1rem;
    color: black;
  }

  @media screen and (max-width: 768px) {
    &__input {
    border: 2px solid #2B2923;
    }
  }
}

