.video-list {
  padding: 1rem 0;
  &__header {
    padding: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    justify-content: space-between;

    &--center {
      justify-content: center;
    }
  }

  &__title {
    font-size: 1.5rem;
    color: var(--text-color);
    font-weight: bold;
    margin: 0;

    &--center {
      text-align: center;
    }

    &__icon {
      color: var(--light-main-color);
      margin-right: 1rem;
    }

    &--wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      * > {
        margin: 0 .5rem;
        font-size: 1.5rem;
      }

      .video-list__title__icon {
        margin-right: 1rem;
      }
    }
  }

  &__items {
    .pub {
      order: 0;
      width: 25%;
    }
  }

  &--facetting {
    padding: 0 1rem;
    width: 100%;
  }

  &--unavoidable {
    background: #1d1d1d;
    margin-bottom: 1rem;

    .video-list {
      &__content {
        padding: 0 1rem;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .video-list {
    &__header {
      flex-wrap: wrap;
    }

    .dropdown {
      width: 100%;

      &-list__button {
        width: 100%;
        margin-top: 1rem;
      }
    }
  }
}

.video-elite {
  display: block;
  position: relative;

  &__cover {
    width:100%;
      img {
        width: 100%;
        display: block;
      }
  }

  &__background {
    width: 100%;
    display: block;
    position: relative;
    padding-top: calc(650 / 1920 * 100%);
    height: 0;

    img {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }
  }

  @media screen and (max-width: 768px) {
    &__background {
      display: none !important;
    }
  }
}
